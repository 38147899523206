<template>
  <van-popup v-model:show="showLedgerType" position="bottom" round>
    <van-picker
      :columns="ledgerColumns"
      @cancel="onCancel"
      @confirm="onSelectLedger"
    />
  </van-popup>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, Ref, toRefs } from 'vue';
import { ledgerTypeDict, LedgerTypeDictTypes } from '@/dataDict';
import type { PickerConfirmEventParams } from 'vant';

export default defineComponent({
  name: 'ledger-type-popup',
  props: {
    showLedgerType: { type: Boolean },
    excludeLedger: { type: Array },
    ledgerType: { type: [Number, String] },
  },
  setup(props, ctx: any): any {
    const data = reactive({
      ledgerColumns: props.excludeLedger
        ? ledgerTypeDict
            .toList((v) => !props.excludeLedger?.includes(v.key))
            .map((v: any) => ({ text: v.title, value: v.title }))
        : ledgerTypeDict
            .toList()
            .map((v: any) => ({ text: v.title, value: v.title })),
    });

    const methods = {
      onCancel() {
        ctx.emit('update:showLedgerType', false);
      },
      onSelectLedger(v: PickerConfirmEventParams) {
        ctx.emit('update:ledgerType', v.selectedValues[0]);
        methods.onCancel();
        ctx.emit('onSelectLedger', v.selectedValues[0]);
      },
    };

    return {
      ...toRefs(data),
      ...methods,
    };
  },
});
</script>

<style lang="scss" scoped></style>
