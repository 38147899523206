import Icon from './Icon.vue';
import NavBar from './NavBar.vue';

const components = [Icon, NavBar];

export default {
  install(Vue: any) {
    for (const component of components)
      Vue.component(component.name, component);
  },
};
