import { budgetRequest } from '@/utils/http/httpRequestIndex';

export class BudgetReq {
  static myAllBudget() {
    return budgetRequest.get('/my_all_budget');
  }
  static budgetPercentInfo() {
    return budgetRequest.get('/budge_percent_info');
  }
  static budgetCreate(params: object) {
    return budgetRequest.post('/create', params);
  }
  static budgetUpdate(params: object) {
    return budgetRequest.post('/update', params);
  }
}
