import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "user" }
const _hoisted_2 = { class: "head" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "slogen" }
const _hoisted_6 = {
  key: 0,
  class: "budge-card"
}
const _hoisted_7 = { class: "flex-row-all-center" }
const _hoisted_8 = { class: "budge-card__content" }
const _hoisted_9 = { class: "budge-item" }
const _hoisted_10 = { class: "budge-item" }
const _hoisted_11 = { class: "budge-item" }
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "content-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_tag = _resolveComponent("van-tag")!
  const _component_van_collapse_item = _resolveComponent("van-collapse-item")!
  const _component_van_collapse = _resolveComponent("van-collapse")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_dialog = _resolveComponent("van-dialog")!
  const _component_van_rate = _resolveComponent("van-rate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[11] || (_cache[11] = _createElementVNode("img", {
        class: "avatar",
        src: "/common/images/avatar.png"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.user.username || ''), 1),
        _createElementVNode("span", _hoisted_5, "个性签名：" + _toDisplayString(_ctx.user.signature || ''), 1)
      ])
    ]),
    (_ctx.showBudgetCard)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("header", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString($setup.month) + "月总预算", 1),
            _cache[12] || (_cache[12] = _createElementVNode("div", null, [
              _createElementVNode("span", null, "查看全部")
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: ".budge-chart-wrap" }, [
              _createElementVNode("div", { id: "BudgetChart2" })
            ], -1)),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, [
                _cache[13] || (_cache[13] = _createElementVNode("label", null, "剩余预算：", -1)),
                _createElementVNode("span", null, _toDisplayString($setup.info.suplus), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[14] || (_cache[14] = _createElementVNode("label", null, "本月预算：", -1)),
                _createElementVNode("span", null, _toDisplayString($setup.info.budget.money), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[15] || (_cache[15] = _createElementVNode("label", null, "本月支出：", -1)),
                _createElementVNode("span", null, _toDisplayString(Number($setup.info.totalMoney).toFixed(2)), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_van_cell, {
          icon: "orders-o",
          onClick: _ctx.toConfigBillType,
          title: "配置类别",
          "is-link": ""
        }, null, 8, ["onClick"]),
        _createVNode(_component_van_cell, {
          icon: "edit",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visibleSig = true)),
          title: "修改个性签名",
          "is-link": ""
        }),
        _createVNode(_component_van_cell, {
          icon: "setting-o",
          to: "/account",
          title: "账户安全",
          "is-link": ""
        }),
        _createVNode(_component_van_cell, {
          icon: "bag-o",
          to: "/takeout_praise",
          title: "外卖好评",
          "is-link": ""
        }),
        _createVNode(_component_van_cell, {
          icon: "bag-o",
          to: "/target",
          title: "目标管理",
          "is-link": ""
        }),
        _createVNode(_component_van_cell, {
          icon: "good-job-o",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visibleRate = true)),
          title: "软件评分",
          "is-link": ""
        }),
        _createVNode(_component_van_collapse, {
          class: "bottom-collapse",
          modelValue: _ctx.activeNames,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.activeNames) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_collapse_item, {
              icon: "shop-o",
              title: "换肤",
              name: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_van_cell, {
                  title: "亮色",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeColor('light')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_van_tag, { color: "#ffda44" }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("#ffda44")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_van_cell, {
                  title: "浅绿",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeColor('green')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_van_tag, { color: "#39be77" }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode("#39be77")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_van_cell, {
                  title: "粉色",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeColor('pink')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_van_tag, { color: "#faafaa" }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode("#faafaa ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_van_cell, {
                  title: "蓝色",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeColor('blue')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_van_tag, { color: "#87ceeb" }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode("#87ceeb ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createVNode(_component_van_dialog, {
      show: _ctx.visibleSig,
      "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.visibleSig) = $event)),
      onConfirm: _ctx.confirmSig,
      title: "个性签名",
      "show-cancel-button": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_field, {
          modelValue: _ctx.signature,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.signature) = $event)),
          rows: "2",
          type: "textarea",
          maxlength: "20",
          placeholder: "请输入个性签名",
          "show-word-limit": "",
          autosize: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["show", "onConfirm"]),
    _createVNode(_component_van_dialog, {
      class: "rate",
      show: _ctx.visibleRate,
      "onUpdate:show": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.visibleRate) = $event)),
      title: "软件评分",
      "show-cancel-button": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_rate, {
          class: "rate",
          modelValue: _ctx.rate,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.rate) = $event)),
          "void-icon": "star",
          "void-color": "#eee",
          "allow-half": ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}