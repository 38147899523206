import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_dialog = _resolveComponent("van-dialog")!

  return (_openBlock(), _createBlock(_component_van_dialog, {
    show: _ctx.visible,
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
    onConfirm: _ctx.confirmBudget,
    onCancel: _ctx.cancel,
    title: "预算",
    "show-cancel-button": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_field, {
        ref: "budgetInputRef",
        modelValue: _ctx.allBudget,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.allBudget) = $event)),
        rows: "2",
        type: "number",
        maxlength: "20",
        placeholder: "请输入每月预算",
        "show-word-limit": "",
        autosize: "",
        autofocus: ""
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["show", "onConfirm", "onCancel"]))
}