import { billRequest, billTypesRequest } from '@/utils/http/httpRequestIndex';

export class BillReq {
  static borrowList(params?: object) {
    return billRequest.get('/borrow_list', params);
  }
  static billList(params: object) {
    return billRequest.get('/list', params);
  }
  static billStat(params: object) {
    return billRequest.get('/stat', params);
  }
  static billCreate(params: object) {
    return billRequest.post('/create', params);
  }
  static billDelete(id: number) {
    return billRequest.get('/delete', { id });
  }
  static billDetail(id: number) {
    return billRequest.get('/detail', { id });
  }
  static billUpdate(data: object) {
    return billRequest.post('/update', data);
  }
  static billTypeList(data: object = {}) {
    return billTypesRequest.get('/list', data);
  }
  static investStat(data?: object) {
    return billRequest.get('/invest_stat', data);
  }
  static stopCycleTask(bill_cycle_id: number) {
    return billRequest.post('/stop_cycle_task', { bill_cycle_id });
  }
}
