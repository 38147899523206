import { DataDictItem } from '@/utils/dataDict';

export enum SourceTypeDictTypes {
  EXPENSE = 'expense',
  INCOME = 'income',
}

export const sourceTypeDict = new DataDictItem([
  // 支出
  { key: SourceTypeDictTypes.EXPENSE, value: 1, title: '支出' },
  // 收入
  { key: SourceTypeDictTypes.INCOME, value: 2, title: '收入' },
]);

export enum LedgerTypeDictTypes {
  BASE_LEDGER = 'base_ledger',
  INVEST_LEDGER = 'invest_ledger',
  CYCLE_LEDGER = 'cycle_ledger',
  TOTOAN_LEDGER = 'toloan_ledger',
}

export const ledgerTypeDict = new DataDictItem([
  // 基础账本
  { key: LedgerTypeDictTypes.BASE_LEDGER, value: 1, title: '基础账本' },
  // 投资账本
  { key: LedgerTypeDictTypes.INVEST_LEDGER, value: 2, title: '投资账本' },
  // 周期账本
  { key: LedgerTypeDictTypes.CYCLE_LEDGER, value: 3, title: '周期账本' },
  // 周期账本
  { key: LedgerTypeDictTypes.TOTOAN_LEDGER, value: 4, title: '借贷账本' },
]);

export enum HoldingStatusTypes {
  POSITION = 'position',
  SOLD = 'sold',
}

export const holdingStatusDict = new DataDictItem([
  // 持仓中
  { key: HoldingStatusTypes.POSITION, value: 1, title: '持仓中' },
  // 已卖出
  { key: HoldingStatusTypes.SOLD, value: 2, title: '已卖出' },
]);

export enum CycleTaskStatusTypes {
  RUNNING = 'running',
  STOPPED = 'stopped',
}

export const cycleTaskStatusDict = new DataDictItem([
  // 周期账单运行中
  { key: CycleTaskStatusTypes.RUNNING, value: 1, title: '账单周期内' },
  // 周期账单截止
  { key: CycleTaskStatusTypes.STOPPED, value: 2, title: '账单周期截止' },
]);

export enum MoneyTypes {
  CNY = 'cny',
  USD = 'usd',
}

export const moneyTypeDict = new DataDictItem([
  // 人民币
  { key: MoneyTypes.CNY, value: 1, code: 'cny', title: '人民币', symbol: '￥' },
  // 美元
  { key: MoneyTypes.USD, value: 2, code: 'usd', title: '美元', symbol: '$' },
]);
