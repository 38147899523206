import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "add-wrap" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  key: 0,
  class: "cycle-ledger-info"
}
const _hoisted_4 = { class: "filter" }
const _hoisted_5 = { class: "type" }
const _hoisted_6 = { class: "flex-row-all-center" }
const _hoisted_7 = {
  key: 1,
  class: "cycle-ledger-info"
}
const _hoisted_8 = {
  key: 2,
  class: "type-warp"
}
const _hoisted_9 = {
  key: 0,
  class: "type-body"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "type-name" }
const _hoisted_12 = {
  key: 1,
  class: "type-body"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 3,
  class: "remark",
  style: {"padding-top":"16px"}
}
const _hoisted_15 = { class: "remark" }
const _hoisted_16 = { class: "flex-row jus-between item-center money-wrap" }
const _hoisted_17 = { class: "amount animation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_icon = _resolveComponent("icon")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_number_keyboard = _resolveComponent("van-number-keyboard")!
  const _component_van_calendar = _resolveComponent("van-calendar")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!
  const _component_ledger_type_popup = _resolveComponent("ledger-type-popup")!

  return (_openBlock(), _createBlock(_component_van_popup, {
    show: _ctx.show,
    "onUpdate:show": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.show) = $event)),
    position: "bottom",
    style: { minHeight: '90vh' },
    round: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("span", {
            class: "close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
          }, [
            _createVNode(_component_van_icon, { name: "cross" })
          ])
        ]),
        (_ctx.form.ledger_type === '周期账本')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "注意：周期账本会在每月1号自动生成一笔记账"))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeType('expense'))),
              class: _normalizeClass({ expense: true, active: _ctx.form.source_type == 'expense' })
            }, " 支出 ", 2),
            _withDirectives(_createElementVNode("span", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeType('income'))),
              class: _normalizeClass({ income: true, active: _ctx.form.source_type == 'income' })
            }, " 收入 ", 2), [
              [_vShow, _ctx.showIncomeBtn]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "ledger-type",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showLedgerType = true))
            }, [
              _createTextVNode(_toDisplayString(_ctx.form.ledger_type) + " ", 1),
              _createVNode(_component_icon, { "icon-class": "icon-arrow_down_fat" })
            ])
          ])
        ]),
        (_ctx.money_symbol === '$')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, "保存后，金额会按汇率兑换为人民币"))
          : _createCommentVNode("", true),
        (!_ctx.isToloanLedger)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_ctx.form.source_type == 'expense')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expense, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "type-item",
                        key: item.id,
                        onClick: _withModifiers(($event: any) => (_ctx.choseType(item)), ["stop"])
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass({
                'iconfont-wrap': true,
                expense: true,
                active: _ctx.form.currentType.id == item.id,
              })
                        }, [
                          _createElementVNode("i", {
                            class: _normalizeClass(["iconfont", item.icon])
                          }, null, 2)
                        ], 2),
                        _createElementVNode("span", _hoisted_11, _toDisplayString(item.name), 1)
                      ], 8, _hoisted_10))
                    }), 128)),
                    _cache[18] || (_cache[18] = _createTextVNode(" . ")),
                    _createElementVNode("div", {
                      class: "type-item",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toConfigBillType(1)))
                    }, _cache[17] || (_cache[17] = [
                      _createElementVNode("span", { class: "iconfont-wrap" }, [
                        _createElementVNode("span", { class: "iconfont icon-shezhi" })
                      ], -1),
                      _createElementVNode("span", { class: "type-name" }, "设置", -1)
                    ]))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.income, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "type-item",
                        key: item.id,
                        onClick: ($event: any) => (_ctx.choseType(item))
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass({
                'iconfont-wrap': true,
                income: true,
                active: _ctx.form.currentType.id == item.id,
              })
                        }, [
                          _createElementVNode("i", {
                            class: _normalizeClass(["iconfont", item.icon])
                          }, null, 2)
                        ], 2),
                        _createElementVNode("span", null, _toDisplayString(item.name), 1)
                      ], 8, _hoisted_13))
                    }), 128)),
                    _createElementVNode("div", {
                      class: "type-item",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toConfigBillType(2)))
                    }, _cache[19] || (_cache[19] = [
                      _createElementVNode("span", { class: "iconfont-wrap" }, [
                        _createElementVNode("span", { class: "iconfont icon-shezhi" })
                      ], -1),
                      _createElementVNode("span", null, "设置", -1)
                    ]))
                  ]))
            ]))
          : _createCommentVNode("", true),
        (_ctx.isToloanLedger)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _cache[20] || (_cache[20] = _createElementVNode("label", { class: "remark-label" }, "借贷人", -1)),
              _createVNode(_component_van_field, {
                modelValue: _ctx.form.borrower,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.borrower) = $event)),
                maxlength: "50",
                placeholder: "请输入借贷人",
                "show-word-limit": ""
              }, null, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          _cache[21] || (_cache[21] = _createElementVNode("label", { class: "remark-label" }, "备注", -1)),
          _createVNode(_component_van_field, {
            modelValue: _ctx.form.remark,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.remark) = $event)),
            rows: 1,
            type: "textarea",
            maxlength: "200",
            placeholder: "请输入备注",
            "show-word-limit": "",
            autosize: ""
          }, null, 8, ["modelValue"])
        ]),
        _createVNode(_component_van_number_keyboard, {
          show: true,
          theme: "custom",
          "extra-key": ['00', '.'],
          "close-button-text": "完成",
          onDelete: _ctx.remove,
          onClose: _ctx.addBill,
          onInput: _ctx.inputChange
        }, {
          "title-left": _withCtx(() => [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", {
                class: "money",
                onClick: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("span", {
                  class: "sufix",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showMoneyType = !_ctx.isEdit))
                }, _toDisplayString(_ctx.money_symbol), 1),
                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.form.money), 1)
              ]),
              _createElementVNode("div", {
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showDay = true)),
                class: "time"
              }, [
                _createTextVNode(_toDisplayString(_ctx.transDay(_ctx.form.bill_date)) + " ", 1),
                _createVNode(_component_icon, { "icon-class": "icon-arrow_down_fat" })
              ])
            ])
          ]),
          _: 1
        }, 8, ["onDelete", "onClose", "onInput"])
      ]),
      _createVNode(_component_van_calendar, {
        ref: "billDateCalendarRef",
        show: _ctx.showDay,
        "onUpdate:show": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.showDay) = $event)),
        "min-date": _ctx.minDate,
        "show-confirm": false,
        color: _ctx.themeColor,
        onConfirm: _ctx.choseDay,
        onOpen: _ctx.billDateCalendarOpened
      }, null, 8, ["show", "min-date", "color", "onConfirm", "onOpen"]),
      _createVNode(_component_van_popup, {
        show: _ctx.showMoneyType,
        "onUpdate:show": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.showMoneyType) = $event)),
        position: "bottom",
        round: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_van_picker, {
            columns: _ctx.moneyColumns,
            onCancel: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showMoneyType = false)),
            onConfirm: _ctx.onSelectMoneyType
          }, null, 8, ["columns", "onConfirm"])
        ]),
        _: 1
      }, 8, ["show"]),
      _createVNode(_component_ledger_type_popup, {
        "show-ledger-type": _ctx.showLedgerType,
        "onUpdate:showLedgerType": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.showLedgerType) = $event)),
        "ledger-type": _ctx.form.ledger_type,
        "onUpdate:ledgerType": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.ledger_type) = $event)),
        "exclude-cycle-ledger": $props.excludeCycleLedger,
        onOnSelectLedger: _ctx.onSelectLedger
      }, null, 8, ["show-ledger-type", "ledger-type", "exclude-cycle-ledger", "onOnSelectLedger"])
    ]),
    _: 1
  }, 8, ["show"]))
}