<template>
  <van-dialog
    v-model:show="visible"
    @confirm="confirmBudget"
    @cancel="cancel"
    title="预算"
    show-cancel-button
  >
    <van-field
      ref="budgetInputRef"
      v-model="allBudget"
      rows="2"
      type="number"
      maxlength="20"
      placeholder="请输入每月预算"
      show-word-limit
      autosize
      autofocus
    />
  </van-dialog>
</template>

<script lang="ts">
import { BudgetReq } from '@/request/budget';
import { useLoading } from '@/utils/hooks';
import { defineComponent, reactive, toRefs, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { AllBudgetOpType } from '@/types/components/index';

export default defineComponent({
  name: 'all-budget',
  components: {},
  props: {
    visible: { type: Boolean },
    type: { type: String as PropType<AllBudgetOpType> },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const [, budgetRun, budgetDone] = useLoading('保存中...');
    const state = reactive({ allBudget: '' });

    const methods = {
      // 保存预算
      confirmBudget: budgetRun(() => {
        BudgetReq[props.type === 'add' ? 'budgetCreate' : 'budgetUpdate']({
          money: state.allBudget,
        })
          .then(() => {
            emit('update:visible', false);
            emit('saveDone');
          })
          .finally(() => budgetDone());
      }),
      cancel() {
        emit('update:visible', false);
      },
    };

    return {
      ...toRefs(state),
      ...methods,
    };
  },
});
</script>

<style lang="scss" scoped></style>
