import { BaseObject } from '@/types/utils/baseTypes';
import { safePropPath, notNull } from '@/utils/judgeTool';

export interface DictItem extends BaseObject {
  key: string;
  value: any;
}

/**
 * 数据字典对象，用于管理状态键值对
 * 方便状态码的判断与管理
 */
export class DataDictItem<T extends DictItem> {
  data: Array<T> = [];

  constructor(data: Array<T> = []) {
    this.data = data;
  }

  /**
   * 根据key值查字典对象
   */
  get(key: string) {
    let itemList = this.data.filter((item) => item.key === key);
    if (itemList && itemList.length) return itemList[0];
  }

  /**
   * 根据value值查字典对象
   */
  getByVal(value: any) {
    let itemList = this.data.filter((item) => item.value === value);
    if (itemList && itemList.length) return itemList[0];
  }

  /**
   * 根据自定义属性名，查询指定属性值的对象
   * @returns
   */
  getByCusKey(column: string, columnVal: any) {
    let itemList = this.data.filter((item: any) => item[column] === columnVal);
    if (itemList && itemList.length) return itemList[0];
  }

  /**
   * 根据一个key返回字典对象的值
   */
  val(key: string) {
    let valObj = this.get(key);
    return valObj ? valObj.value : null;
  }

  /**
   * 得到键值对的字典
   */
  toMap() {
    let map: BaseObject = {};
    for (const item of this.data) {
      map[item.key] = item;
    }
    return map;
  }

  /**
   * 得到字典列表
   */
  toList(callback?: (v: DictItem, i: number) => {}) {
    if (callback) return this.data.filter(callback);
    return this.data;
  }

  /**
   * 比较一个key找出字典对象的值，和传参的值是否相等
   * 通常用来判断状态
   * @param key   一个key
   * @param value 一个值
   * @returns
   */
  equal(key: string, value: any) {
    return value === this.val(key);
  }

  /**
   * 安全比较，比如 data.xxx.xxx.xx === 1这种比较。
   * 会处理路径搜索不到的情况
   * @param object 数据对象
   * @param propertyPath 属性路径
   * @param key 状态值对应的key
   * @returns DictItem
   */
  safeEqual(object: object, propertyPath: string, key: string) {
    let value = safePropPath(object, propertyPath);
    if (notNull(value)) return this.equal(value, key);
    return false;
  }

  /**
   * 根据用户传入字段，比较值
   * @param column 字段名
   * @param key 关键字
   * @param value 所在字段的值
   */
  cusEqual(column: string, key: string, value: any) {
    return this.val(key) === this.getByCusKey(column, value)?.value;
  }
}
