import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: `icon-wrap${_ctx.renClass(_ctx.wrapClass)}`
  }, _ctx.$listener), [
    _createElementVNode("div", {
      class: _normalizeClass(`iconfont icon${_ctx.renClass(_ctx.iconClass)}`),
      style: _normalizeStyle(_ctx.iconStyle)
    }, null, 6)
  ], 16))
}