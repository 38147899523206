import storage from '@/utils/storage';
import { BaseObject } from './../utils/baseTypes';
import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store, Module } from 'vuex';
import router from '@/router';
import { UsersReq } from '@/request/users';
import { testModule } from './testModule';

interface UserInfo {
  username: string;
  nicke_name: string;
  [propName: string]: any;
}

export interface State extends BaseObject {
  token: string;
  userInfo: UserInfo;
  homeSearchLedgerType: string;
  mainActiveTab: string;
  cacheRouteList: Set<string>;
  animation: string; //全局路由切换动画
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  modules: {
    testModule,
  },
  state: {
    token: storage.getItem('token'),
    userInfo: { username: '', nicke_name: '' },
    homeSearchLedgerType: '基础账本',
    mainActiveTab: 'home',
    cacheRouteList: new Set(),
    animation: 'slide-right',
  },
  getters: {
    cacheRouteList(state) {
      return [...state.cacheRouteList];
    },
  },
  mutations: {
    setAnimation(state, v: string) {
      state.animation = v;
    },
    setCacheRouteList(state, v: string[]) {
      state.cacheRouteList = new Set(v);
    },
    hasCacheRoute(state, v: string) {
      return state.cacheRouteList.has(v);
    },
    addCacheRoute(state, v: string) {
      state.cacheRouteList.add(v);
      state.cacheRouteList = new Set([...state.cacheRouteList]);
    },

    delCacheRoute(state, v: string) {
      if (state.cacheRouteList.has(v)) state.cacheRouteList.delete(v);
      state.cacheRouteList = new Set([...state.cacheRouteList]);
    },
    clearBillCache(state) {
      state.cacheRouteList.delete('home');
      state.cacheRouteList.delete('statistics');
      state.cacheRouteList.delete('invest');
      state.cacheRouteList.delete('user');
      state.cacheRouteList.delete('budget');
      state.cacheRouteList.delete('toloan_list');
      state.cacheRouteList = new Set([...state.cacheRouteList]);
    },
    setHomeSearchLedgerType(state: State, v: string) {
      state.homeSearchLedgerType = v;
    },
    // 设置token
    setToken(state: State, token: string) {
      state.token = token;
      storage.setItem('token', token);
    },
    // 设置信息
    setUserInfo(state: State, info: UserInfo) {
      state.userInfo = info;
    },
    switchTab(
      state: State,
      item: { name: string; path: string; params?: BaseObject }
    ) {
      state.mainActiveTab = item.name;
      document.documentElement.scrollTo({ top: 0 })
      router.replace({ name: item.name, params: item.params || {} });
    },
    clearAppState(state) {
      state.mainActiveTab = '';
      state.cacheRouteList = new Set([]);
      state.token = '';
      storage.removeItem('token');
    },
  },
  actions: {
    // 获取信息
    getUserInfo(context: any) {
      return new Promise((resolve, reject) => {
        UsersReq.getUserInfo()
          .then((res) => {
            context.commit('setUserInfo', res.data);
            resolve(res);
          })
          .catch((err: Error) => reject(err));
      });
    },
    // 登录
    login(context: any, data: any) {
      return new Promise((resolve, reject) => {
        UsersReq.login(data)
          .then((res) => {
            context.commit('setToken', res.data);
            resolve(res);
          })
          .catch((err: Error) => {
            reject(err);
          });
      });
    },
    // 登出
    logout(context: any) {
      return new Promise((resolve, reject) => {
        context.commit('clearAppState');
        resolve('');
      });
    },
  },
});

// 定义自己的 `useStore` 组合式函数
export function useStore() {
  return baseUseStore(key);
}

export function useCacheStore() {
  return {
    hasCacheRoute(v: string) {
      return store.commit('hasCacheRoute', v);
    },
    addCacheRoute(v: string) {
      store.commit('addCacheRoute', v);
    },
    delCacheRoute(v: string) {
      store.commit('delCacheRoute', v);
      // 在删除缓存时马上跳转会出问题。
      // 可能已经进入了缓存过的页面，然后再清楚缓存的情况
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(store.getters.cacheRouteList);
        }, 300);
      });
    },
    clearBillCache() {
      store.commit('clearBillCache');
    },
  };
}
