<template>
  <van-popup v-model:show="show" position="bottom" :style="{ minHeight: '90vh' }" round>
    <div class="add-wrap">
      <header class="header">
        <span class="close" @click="show = false">
          <van-icon name="cross" />
        </span>
        <!-- <van-button
          class="add-btn"
          type="primary"
          size="small"
          @click="addBill"
        >
          确定
        </van-button> -->
      </header>
      <div class="cycle-ledger-info" v-if="form.ledger_type === '周期账本'">注意：周期账本会在每月1号自动生成一笔记账</div>
      <div class="filter">
        <div class="type">
          <span @click="changeType('expense')" :class="{ expense: true, active: form.source_type == 'expense' }"> 支出 </span>
          <span
            v-show="showIncomeBtn"
            @click="changeType('income')"
            :class="{ income: true, active: form.source_type == 'income' }"
          >
            收入
          </span>
        </div>
        <div class="flex-row-all-center">
          <div class="ledger-type" @click="showLedgerType = true">
            {{ form.ledger_type }}
            <icon icon-class="icon-arrow_down_fat" />
          </div>
        </div>
      </div>
      <div class="cycle-ledger-info" v-if="money_symbol === '$'">保存后，金额会按汇率兑换为人民币</div>
      <div v-if="!isToloanLedger" class="type-warp">
        <div class="type-body" v-if="form.source_type == 'expense'">
          <div class="type-item" v-for="item in expense" :key="item.id" @click.stop="choseType(item)">
            <span
              :class="{
                'iconfont-wrap': true,
                expense: true,
                active: form.currentType.id == item.id,
              }"
            >
              <i class="iconfont" :class="item.icon" />
            </span>
            <span class="type-name">{{ item.name }}</span>
          </div>
          .
          <div class="type-item" @click="toConfigBillType(1)">
            <span class="iconfont-wrap">
              <span class="iconfont icon-shezhi"></span>
            </span>
            <span class="type-name">设置</span>
          </div>
        </div>
        <div class="type-body" v-else>
          <div class="type-item" v-for="item in income" :key="item.id" @click="choseType(item)">
            <span
              :class="{
                'iconfont-wrap': true,
                income: true,
                active: form.currentType.id == item.id,
              }"
              ><i class="iconfont" :class="item.icon"
            /></span>
            <span>{{ item.name }}</span>
          </div>
          <div class="type-item" @click="toConfigBillType(2)">
            <span class="iconfont-wrap">
              <span class="iconfont icon-shezhi"></span>
            </span>
            <span>设置</span>
          </div>
        </div>
      </div>
      <div v-if="isToloanLedger" class="remark" style="padding-top: 16px">
        <label class="remark-label">借贷人</label>
        <van-field v-model="form.borrower" maxlength="50" placeholder="请输入借贷人" show-word-limit />
      </div>
      <div class="remark">
        <label class="remark-label">备注</label>
        <van-field
          v-model="form.remark"
          :rows="1"
          type="textarea"
          maxlength="200"
          placeholder="请输入备注"
          show-word-limit
          autosize
        />
      </div>
      <van-number-keyboard
        :show="true"
        theme="custom"
        :extra-key="['00', '.']"
        close-button-text="完成"
        @delete="remove"
        @close="addBill"
        @input="inputChange"
      >
        <template #title-left>
          <div class="flex-row jus-between item-center money-wrap">
            <div class="money" @click.stop="">
              <span class="sufix" @click="showMoneyType = !isEdit">
                {{ money_symbol }}
              </span>
              <span class="amount animation">{{ form.money }}</span>
            </div>
            <div @click="showDay = true" class="time">
              {{ transDay(form.bill_date) }}
              <icon icon-class="icon-arrow_down_fat" />
            </div>
          </div>
        </template>
      </van-number-keyboard>
    </div>

    <van-calendar
      ref="billDateCalendarRef"
      v-model:show="showDay"
      :min-date="minDate"
      :show-confirm="false"
      :color="themeColor"
      @confirm="choseDay"
      @open="billDateCalendarOpened"
    />

    <!-- <van-popup v-model:show="showDay" position="bottom" round>
      <van-datetime-picker
        v-model="form.bill_date"
        type="date"
        title="选择时间"
        @confirm="choseDay"
        @cancel="showDay = false"
      />
    </van-popup> -->

    <van-popup v-model:show="showMoneyType" position="bottom" round>
      <van-picker :columns="moneyColumns" @cancel="showMoneyType = false" @confirm="onSelectMoneyType" />
    </van-popup>

    <ledger-type-popup
      v-model:show-ledger-type="showLedgerType"
      v-model:ledger-type="form.ledger_type"
      :exclude-cycle-ledger="excludeCycleLedger"
      @onSelectLedger="onSelectLedger"
    />
  </van-popup>
</template>

<script lang="ts">
import { reactive, toRefs, onMounted, ref, Ref, nextTick, computed, watch, onActivated, getCurrentInstance } from 'vue';
import { showToast, showSuccessToast, showFailToast } from 'vant';
import dayjs from 'dayjs';
import LedgerTypePopup from '@/components/service/LedgerTypePopup';
import { useRouter } from 'vue-router';
import type { FieldInstance, CalendarInstance } from 'vant';
import { sourceTypeDict, SourceTypeDictTypes, ledgerTypeDict, LedgerTypeDictTypes, moneyTypeDict, MoneyTypes } from '@/dataDict';
import { isNull, notNull } from '@/utils/judgeTool';
import { BillReq } from '@/request/bill';
import { BaseObject } from '@/utils/baseTypes';
import { useCacheStore } from '@/store';
import { variables } from '@/theme/variables';

const initForm: {
  source_type: string;
  currentType: any;
  bill_date: Date | string;
  ledger_type: string;
  money: string;
  borrower: string;
  remark: string;
} = {
  source_type: 'expense',
  currentType: {},
  bill_date: new Date(),
  ledger_type: '基础账本',
  money: '', // 账单价格
  borrower: '',
  remark: '',
};

const moneyTypeList = moneyTypeDict.toList().map((v) => v?.title);

export default {
  name: 'PopAdd',
  components: { LedgerTypePopup },
  props: {
    refresh: Function,
    detail: {
      type: Object,
      default: {},
    },
    excludeCycleLedger: { type: Boolean },
  },
  setup(props: any, { emit }: any) {
    const id = props.detail && props.detail.id;
    const router = useRouter();
    const billDateCalendarRef: Ref<CalendarInstance | undefined> = ref();
    const { clearBillCache } = useCacheStore();
    const state = reactive({
      form: { ...initForm },
      money_symbol: moneyTypeDict.get(MoneyTypes.CNY)?.symbol,
      moneyColumns: moneyTypeList,
      show: false, // 显示隐藏添加账单弹窗
      expense: [], // 支出类型数组
      income: [], // 收入类型数组
      showDay: false, // 选择日期显示/隐藏
      showLedgerType: false,
      showMoneyType: false,
      themeColor: '#ffda44',
      minDate: new Date(),
    });

    // 如果是修改，赋一下数据
    if (notNull(id)) {
      let sourceTypeObj: any = sourceTypeDict.getByVal(props.detail.source_type);
      let ledgerTypeObj: any = ledgerTypeDict.getByVal(props.detail.ledger_type);

      let detail = props.detail;
      state.form = {
        source_type: sourceTypeObj.key,
        currentType: {
          id: detail.bill_type,
          name: detail.bill_type_name,
        },
        bill_date: new Date(dayjs(detail.bill_date).format('YYYY-MM-DD')),
        ledger_type: ledgerTypeObj.title,
        money: detail.money, // 账单价格
        remark: detail.remark,
        borrower: detail.borrower,
      };
    }

    const computeds = {
      // 是否展示收入选项
      showIncomeBtn: computed(
        () =>
          !ledgerTypeDict.equal(
            LedgerTypeDictTypes.INVEST_LEDGER,
            ledgerTypeDict.getByCusKey('title', state.form.ledger_type)?.value
          )
      ),
      // 是否修改
      isEdit: computed(() => notNull(id)),
      isToloanLedger: computed(() =>
        ledgerTypeDict.equal(
          LedgerTypeDictTypes.TOTOAN_LEDGER,
          ledgerTypeDict.getByCusKey('title', state.form.ledger_type)?.value
        )
      ),
    };

    watch(
      () => state.show,
      (newVal: any) => {
        if (newVal === true) {
          if (isNull(id)) {
            state.form = { ...initForm };
          }
        }
      }
    );

    onActivated(() => {
      methods.initData();
    });

    onMounted(async () => {
      methods.initData();

      // 设置主题色
      let theme: any = variables[localStorage.getItem('data-theme') || 'light'];
      state.themeColor = theme['--bg-color3'];
      state.minDate = new Date(dayjs().subtract(6, 'M').format());
    });

    const methods = {
      // 初始化数据
      async initData() {
        methods.getBillTypeList();
      },
      // 获取账单列表
      async getBillTypeList() {
        let ledger_type = ledgerTypeDict.getByCusKey('title', state.form.ledger_type)?.value;

        if (state.form.ledger_type !== '投资账本') ledger_type = ledgerTypeDict.val(LedgerTypeDictTypes.BASE_LEDGER);

        const {
          data: { list },
        } = await BillReq.billTypeList({
          ledger_type,
        });
        state.expense = list.filter((i: any) => sourceTypeDict.equal(SourceTypeDictTypes.EXPENSE, i.source_type));
        state.income = list.filter((i: any) => sourceTypeDict.equal(SourceTypeDictTypes.INCOME, i.source_type));
      },
      // 添加账单记录
      addBill: async () => {
        if (!state.form.money) {
          showFailToast('请输入具体金额');
          return;
        }
        if (!computeds.isToloanLedger.value && (isNull(state.form.currentType) || isNull(state.form.currentType.id))) {
          showFailToast('必须选择记账类型');
          return;
        }
        if (computeds.isToloanLedger.value && isNull(state.form.borrower)) {
          showFailToast('必须输入借贷人');
          return;
        }
        const params = {
          id: undefined,
          money: Number(state.form.money).toFixed(2),
          bill_type: state.form.currentType.id,
          bill_date: state.form.bill_date,
          source_type: sourceTypeDict.val(state.form.source_type),
          ledger_type: ledgerTypeDict.getByCusKey('title', state.form.ledger_type)?.value,
          money_type: moneyTypeDict.getByCusKey('symbol', state.money_symbol)?.key,
          borrower: state.form.borrower,
          remark: state.form.remark || '',
        };

        if (id) {
          params.id = id;
          // 如果有 id 需要调用详情更新接口
          await BillReq.billUpdate(params).finally(() => clearBillCache());
          state.show = false;
          showSuccessToast('修改成功');
          emit('refresh');
        } else {
          await BillReq.billCreate(params).finally(() => clearBillCache());
          state.form = { ...initForm };
          state.show = false;
          showSuccessToast('添加成功');
          emit('refresh');
        }
      },
      // 弹出记账模态框
      toggle: () => {
        state.show = !state.show;
        if (state.show) {
          methods.initData();
        }
      },
      // 跳转配置账单类型页
      toConfigBillType: (source_type: number) =>
        router.push({
          name: 'config-bill-type',
          params: { source_type },
        }),
    };

    const formMethods = {
      onSelectMoneyType(money_type_title: string) {
        state.money_symbol = moneyTypeDict.getByCusKey('title', money_type_title)?.symbol;
        state.showMoneyType = false;
      },
      // 切换账本类型
      onSelectLedger(ledger_type_title: string) {
        if (ledgerTypeDict.cusEqual('title', LedgerTypeDictTypes.INVEST_LEDGER, ledger_type_title)) {
          state.form.source_type = SourceTypeDictTypes.EXPENSE;
        }
        if (ledgerTypeDict.cusEqual('title', LedgerTypeDictTypes.TOTOAN_LEDGER, ledger_type_title)) {
          state.form.currentType = {};
        }
        methods.getBillTypeList();
      },
      // 监听输入框改变值
      inputChange: (value: string) => {
        // 当输入的值为 '.' 且 已经存在 '.'，则不让其继续字符串相加。
        if (value == '.' && state.form.money.includes('.')) return;
        // 小数点后保留两位，当超过两位时，不让其字符串继续相加。
        if (value != '.' && state.form.money.includes('.') && state.form.money && state.form.money.split('.')[1].length >= 2)
          return;
        state.form.money += value;
      },
      // 格式化时间
      transDay: (time: string) => {
        return dayjs(time).format('MM-DD');
      },
      // 切换收入还是支出
      changeType: (type: 'expense' | 'income') => {
        state.form.source_type = type;
        state.form.currentType = state[type][0];
        methods.getBillTypeList();
      },
      // 删除输入框内容
      remove: () => {
        state.form.money = state.form.money.slice(0, state.form.money.length - 1);
      },
      // 选择花销类型
      choseType: (item: string) => {
        state.form.currentType = item;
      },
      // 选择日期
      choseDay: (value: string) => {
        state.form.bill_date = value;
        state.showDay = false;
      },
      billDateCalendarOpened() {
        billDateCalendarRef?.value?.scrollToDate(
          new Date(
            dayjs(state.form.bill_date as any)
              .subtract(21, 'day')
              .format()
          )
        );
      },
    };

    return {
      billDateCalendarRef,
      ...toRefs(state),
      ...computeds,
      ...methods,
      ...formMethods,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/service/popAdd.scss';
</style>
