const isNull = (data: any) =>
  data === undefined ||
  data === null ||
  data === 'null' ||
  data === 'undefined';

const storageType = { string: 'string', object: 'object' };

const storageOpType = 'localStorage';

const storageTool: any = window[storageOpType];

/**
 * 存储数据
 * @param {*} key 键
 * @param {*} data 数据
 * @param {*} time 超时时间
 */
function setItem(key: string, data: object | string | null, expire?: number) {
  if (!window || !window[storageOpType]) return;
  if (isNull(data)) {
    removeItem(key);
    return;
  }
  try {
    const startTime = new Date().getTime();
    let storeData: any = {
      data,
      startTime,
      type: typeof data === 'object' ? storageType.object : storageType.string,
    };
    if (expire && typeof data === 'object') storeData.expire = expire;
    storageTool.setItem(key, JSON.stringify(storeData));
  } catch (e) {
    console.log(e);
  }
}

/**
 * 获取数据
 * @param {*} key 键
 * @returns
 */
function getItem(key: string) {
  if (!window || !window[storageOpType]) return;
  const endTime = new Date().getTime();
  let storageStr: string | null = storageTool.getItem(key);
  if (isNull(storageStr)) return null;

  try {
    let storageData = JSON.parse(storageStr || '');
    if (storageData.expire) {
      let { expire, data, startTime } = storageData;

      if (isNull(storageStr)) return null;

      if (Number(endTime) - Number(startTime) > Number(expire)) {
        removeItem(key);
        return null;
      } else {
        if (storageData.type === storageType.object) return JSON.parse(data);
        else return data;
      }
    }

    if (storageData && storageData.data) {
      if (typeof storageData.data === storageType.object) {
        return JSON.parse(storageData.data);
      } else {
        return storageData.data;
      }
    }
    return storageData;
  } catch (e) {
    console.log(e);
  }
  return null;
}

/**
 * 删除数据
 * @param {*} key 键
 * @returns
 */
function removeItem(key: string) {
  if (!window || !window[storageOpType]) return;
  storageTool.removeItem(key);
}

const storage = {
  setItem,
  getItem,
  removeItem,
};

export default storage;
