<template>
  <div>
    <div class="tabbar">
      <div class="tabbar-wrap">
        <div
          :class="{
            'tabbar-item': true,
            'tabbar-item--active': active === tabItem.name,
          }"
          v-for="tabItem of tabs"
          :key="tabItem.name"
        >
          <div
            v-if="tabItem.type !== 2"
            class="tabbar-item__node"
            @click="toPage(tabItem)"
          >
            <icon :icon-class="tabItem.icon" />
            <span>{{ tabItem.text }}</span>
          </div>
          <div
            v-else
            class="tabbar-item__node tabbar-item_node--center"
            @click="toAddBill"
          >
            <icon :icon-class="tabItem.icon" />
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <router-view v-slot="{ Component, route }">
        <keep-alive :include="cacheRouteList">
          <component :is="Component" :key="route.name" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted, nextTick, computed } from 'vue';
import { useRouter } from 'vue-router';
import { commonEmitter } from '@/utils/mitt/index';
import { CommonEventType } from '@/utils/mitt/eventType';
import { useStore } from '@/store';

export default {
  name: 'index',
  setup() {
    const store = useStore();
    const tabs = reactive([
      {
        name: 'home',
        path: '/home',
        icon: 'icon-mingxi',
        text: '明细',
      },
      {
        name: 'statistics',
        path: '/statistics',
        icon: 'icon-tongji',
        text: '统计',
      },
      {
        type: 2,
        name: '',
        path: '/',
        icon: 'icon-jiahao',
      },
      {
        name: 'invest',
        path: '/invest',
        icon: 'icon-touzi',
        text: '投资',
      },
      {
        name: 'user',
        path: '/user',
        icon: 'icon-wowode',
        text: '我的',
      },
    ]);
    const router = useRouter();

    const active = computed(() => store.state.mainActiveTab);
    const cacheRouteList = computed(() => store.getters.cacheRouteList);

    const methods = {
      toPage(item) {
        store.commit('switchTab', item);
      },
      toAddBill() {
        store.commit('switchTab', {
          name: 'home',
          params: {
            doAdd: 1,
          },
        });
        commonEmitter.emit(CommonEventType.ShowAddBill);
      },
    };

    return {
      tabs,
      active,
      cacheRouteList,
      ...methods,
    };
  },
};
</script>

<style lang="scss" scoped>
.tabbar {
  transition: 0;
  position: fixed;
  bottom: 0;
  height: 50px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background: #fff;
  width: 100%;
  z-index: 999;
  box-shadow: 0px -1px 6px rgba(171, 171, 171, 0.5);
}
.tabbar-wrap {
  @include flex-row-all-center();
  position: relative;
  justify-content: space-evenly;

  width: 100%;

  .tabbar-item {
    padding: 8px;
    .tabbar-item__node {
      @include flex-column-all-center();
    }
    :deep(.iconfont) {
      color: #a5a5a5;
      font-size: 18px;
      margin-bottom: 5px;
    }
  }
  .tabbar-item--active {
    z-index: 1200;
    :deep(.iconfont) {
      color: var(--text-color5);
    }
    span {
      color: var(--text-color5);
    }
  }
  .tabbar-item_node--center {
    border-radius: 15px;
    background-image: var(--btn-color1);
    position: relative;
    width: 50px;
    height: 40px;
    bottom: 25px;
    :deep(.iconfont) {
      color: #fff;
    }
  }
}
.main {
  width: 100%;
  height: calc(100vh - 50px);
}
.van-tabbar {
  height: 50px;
}
</style>
