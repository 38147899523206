<template>
  <van-popup v-model:show="show" position="bottom" round>
    <van-date-picker
      v-model="currentDate"
      title="选择年月"
      :formatter="formatter"
      :columns-type="columnsType"
      :min-date="minDate"
      :max-date="maxDate"
      :visible-option-num="5"
      @confirm="confirm"
      @cancel="cancel"
    />
  </van-popup>
</template>

<script lang="ts">
import { reactive, toRefs } from 'vue';
import dayjs from 'dayjs';
import type { PickerChangeEventParams, PickerOption } from 'vant';
export default {
  name: 'PopMonth',
  props: {
    selectTime: {
      type: Function,
    },
  },
  setup(props: any, { emit }: any) {
    const currDate = new Date();

    const state = reactive({
      show: false,
      currentDate: [currDate.getFullYear().toString(), (currDate.getMonth() + 1).toString()],
      columnsType: ['year', 'month'],
      minDate: new Date(dayjs().subtract(2, 'year').format()),
      maxDate: new Date(),
    });

    const methods = {
      formatter(type: string, option: PickerOption) {
        if (type === 'year') {
          option.text += '年';
        }
        if (type === 'month') {
          option.text += '月';
        }
        return option;
      },
      toggle() {
        state.show = !state.show;
      },
      confirm(val: PickerChangeEventParams) {
        state.show = false;
        emit('selectTime', val.selectedValues.join("-"));
      },
      cancel() {
        state.show = false;
      },
    };

    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.van-popup) {
  height: 320px;
}
</style>
