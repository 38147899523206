import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "invest-page" }
const _hoisted_2 = { class: "invest-page-wrap" }
const _hoisted_3 = { class: "top-header" }
const _hoisted_4 = { class: "header-row" }
const _hoisted_5 = { class: "mini-card" }
const _hoisted_6 = { class: "mini-card" }
const _hoisted_7 = { class: "mini-card" }
const _hoisted_8 = { class: "header-row" }
const _hoisted_9 = { class: "mini-card" }
const _hoisted_10 = { class: "profit-percent-value" }
const _hoisted_11 = { class: "mini-card" }
const _hoisted_12 = { class: "mini-card" }
const _hoisted_13 = { class: "content" }
const _hoisted_14 = { class: "card" }
const _hoisted_15 = { class: "card" }
const _hoisted_16 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createVNode(_component_icon, { "icon-class": "icon-niandu" }),
              _cache[0] || (_cache[0] = _createTextVNode("当年总收益"))
            ]),
            _createElementVNode("div", null, "￥" + _toDisplayString(_ctx.statData.yearProfit), 1),
            (Number(_ctx.statData.yearProfitPercent))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.getCompareIconClass(_ctx.statData.yearProfitPercent))
                }, [
                  _createVNode(_component_icon, {
                    "icon-class": 
                  _ctx.getCompareIconClass(_ctx.statData.yearProfitPercent, 'icon')
                
                  }, null, 8, ["icon-class"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.statData.yearProfitPercent) + "% ", 1)
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createVNode(_component_icon, { "icon-class": "icon-yuebao" }),
              _cache[1] || (_cache[1] = _createTextVNode("当月总收益"))
            ]),
            _createElementVNode("div", null, "￥" + _toDisplayString(_ctx.statData.monthProfit), 1),
            (Number(_ctx.statData.monthProfitPercent))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.getCompareIconClass(_ctx.statData.monthProfitPercent))
                }, [
                  _createVNode(_component_icon, {
                    "icon-class": 
                  _ctx.getCompareIconClass(_ctx.statData.monthProfitPercent, 'icon')
                
                  }, null, 8, ["icon-class"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.statData.monthProfitPercent) + "% ", 1)
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createVNode(_component_icon, { "icon-class": "icon-jiaoyi" }),
              _cache[2] || (_cache[2] = _createTextVNode("当年卖出交易"))
            ]),
            _createElementVNode("div", null, _toDisplayString(_ctx.statData.sellCount), 1),
            (Number(_ctx.statData.yearSellCountPercent))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.getCompareIconClass(_ctx.statData.yearSellCountPercent))
                }, [
                  _createVNode(_component_icon, {
                    "icon-class": 
                  _ctx.getCompareIconClass(_ctx.statData.yearSellCountPercent, 'icon')
                
                  }, null, 8, ["icon-class"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.statData.yearSellCountPercent) + "% ", 1)
                ], 2))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", null, [
              _createVNode(_component_icon, { "icon-class": "icon-bili" }),
              _cache[3] || (_cache[3] = _createTextVNode("当年收益率"))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", null, _toDisplayString(_ctx.statData.currYearProfitPercent) + "% ", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", null, [
              _createVNode(_component_icon, { "icon-class": "icon-qian" }),
              _cache[4] || (_cache[4] = _createTextVNode("总持仓金额"))
            ]),
            _createElementVNode("div", null, "￥" + _toDisplayString(_ctx.statData.holdMoney), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", null, [
              _createVNode(_component_icon, { "icon-class": "icon-jiaoyi" }),
              _cache[5] || (_cache[5] = _createTextVNode("持仓交易数"))
            ]),
            _createElementVNode("div", null, _toDisplayString(_ctx.statData.holdCount), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("header", null, [
            _createVNode(_component_icon, { "icon-class": "icon-chicang" }),
            _cache[6] || (_cache[6] = _createTextVNode("当前持仓分类"))
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("div", { id: "CurrPositionPie" }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("header", null, [
            _createVNode(_component_icon, { "icon-class": "icon-shouyi" }),
            _cache[8] || (_cache[8] = _createTextVNode("当年收益趋势"))
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { id: "PorfitTrendLine" }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("header", null, [
            _createVNode(_component_icon, { "icon-class": "icon-icon" }),
            _cache[10] || (_cache[10] = _createTextVNode("收益分类"))
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("div", { id: "PorfitPie" }, null, -1))
        ])
      ])
    ])
  ]))
}