import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"
import _imports_0 from '../assets/loading.gif'


const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "top-header" }
const _hoisted_4 = { class: "flex-row-all-center jus-between" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { style: {"margin-right":"3px"} }
const _hoisted_7 = { class: "data-wrap" }
const _hoisted_8 = { class: "expense" }
const _hoisted_9 = { class: "income" }
const _hoisted_10 = { class: "top-nav-wrap" }
const _hoisted_11 = { class: "top-nav flex-row-all-center jus-evenly" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { style: {"padding-top":"5px"} }
const _hoisted_14 = { class: "content-wrap" }
const _hoisted_15 = { class: "list-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_icon = _resolveComponent("icon")!
  const _component_CardItem = _resolveComponent("CardItem")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!
  const _component_ledger_type_popup = _resolveComponent("ledger-type-popup")!
  const _component_all_budget = _resolveComponent("all-budget")!
  const _component_PopType = _resolveComponent("PopType")!
  const _component_PopMonth = _resolveComponent("PopMonth")!
  const _component_PopAdd = _resolveComponent("PopAdd")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "type-wrap",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.PopTypeRefToggle && _ctx.PopTypeRefToggle(...args)))
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.currentSelect.name || '全部类型'), 1),
            _createVNode(_component_van_icon, { name: "apps-o" })
          ]),
          _createElementVNode("div", {
            class: "flex-row-all-center",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showLedgerType = true))
          }, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.ledger_type), 1),
            _createVNode(_component_icon, { "icon-class": "icon-arrow_down_fat" })
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", {
            class: "time",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.PopMonthRefToggle && _ctx.PopMonthRefToggle(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.currentTime), 1),
            _createVNode(_component_van_icon, { name: "arrow-down" })
          ]),
          _createElementVNode("span", _hoisted_8, "总支出 ¥" + _toDisplayString(_ctx.totalExpense), 1),
          _createElementVNode("span", _hoisted_9, "总收入 ¥" + _toDisplayString(_ctx.totalIncome), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (nav, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "nav-item flex-column-all-center",
              key: nav.title,
              onClick: ($event: any) => (_ctx.toPage(nav))
            }, [
              _createVNode(_component_icon, {
                "icon-class": nav.icon
              }, null, 8, ["icon-class"]),
              _createElementVNode("span", _hoisted_13, _toDisplayString(nav.title), 1)
            ], 8, _hoisted_12))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_van_pull_refresh, {
        modelValue: _ctx.refreshing,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.refreshing) = $event)),
        onRefresh: _ctx.onRefresh
      }, {
        pulling: _withCtx((props) => _cache[8] || (_cache[8] = [
          _createElementVNode("img", {
            class: "doge",
            src: _imports_0
          }, null, -1)
        ])),
        loosing: _withCtx(() => _cache[9] || (_cache[9] = [
          _createElementVNode("img", {
            class: "doge",
            src: _imports_0
          }, null, -1)
        ])),
        loading: _withCtx(() => _cache[10] || (_cache[10] = [
          _createElementVNode("img", {
            class: "doge",
            src: _imports_0
          }, null, -1)
        ])),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_van_list, {
              loading: _ctx.loading,
              "onUpdate:loading": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.loading) = $event)),
              finished: _ctx.finished,
              "finished-text": "没有更多了",
              onLoad: _ctx.onLoad
            }, {
              loading: _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("img", {
                  class: "doge",
                  src: _imports_0
                }, null, -1)
              ])),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (v, i) => {
                  return (_openBlock(), _createBlock(_component_CardItem, {
                    key: i,
                    bill: v
                  }, null, 8, ["bill"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["loading", "finished", "onLoad"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onRefresh"])
    ]),
    _createVNode(_component_ledger_type_popup, {
      "show-ledger-type": _ctx.showLedgerType,
      "onUpdate:showLedgerType": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showLedgerType) = $event)),
      "ledger-type": _ctx.ledger_type,
      "onUpdate:ledgerType": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.ledger_type) = $event)),
      "exclude-ledger": ['cycle_ledger'],
      onOnSelectLedger: _ctx.onSelectLedger
    }, null, 8, ["show-ledger-type", "ledger-type", "onOnSelectLedger"]),
    _createVNode(_component_all_budget, {
      visible: _ctx.budgetDialogVisible,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.budgetDialogVisible) = $event)),
      type: "add",
      onSaveDone: _ctx.confirmBudget
    }, null, 8, ["visible", "onSaveDone"]),
    _createVNode(_component_PopType, {
      ref: "PopTypeRef",
      onSelectType: _ctx.selectType
    }, null, 8, ["onSelectType"]),
    _createVNode(_component_PopMonth, {
      ref: "PopMonthRef",
      onSelectTime: _ctx.selectTime
    }, null, 8, ["onSelectTime"]),
    _createVNode(_component_PopAdd, {
      ref: "PopAddRef",
      onRefresh: _ctx.onRefresh
    }, null, 8, ["onRefresh"])
  ]))
}