import { nextTick, onMounted, onUnmounted, reactive, toRefs } from 'vue';
import { variables } from '@/theme/variables';
import { Response } from '@/utils/http/types';
import { notNull } from '@/utils/judgeTool';
import * as echarts from 'echarts';
import { BudgetReq } from '@/request/budget';
import { getFontRpx } from './../chart/index';

function genBudgeOptions(value: number): any {
  let theme: any = variables[localStorage.getItem('data-theme') || 'light'];

  return {
    backgroundColor: 'transparent',
    title: [
      {
        text: '剩余',
        textStyle: {
          color: '#444444',
          fontSize: getFontRpx(0.7),
        },
        itemGap: 10,
        left: '39%',
        top: '38%',
      },
      {
        text: `${value}%`,
        textStyle: {
          color: theme['--text-color3'],
          fontSize: getFontRpx(0.8),
          fontWeight: 'normal',
        },
        itemGap: 20,
        left: '36%',
        top: '50%',
      },
    ],
    angleAxis: {
      polarIndex: 0,
      min: 0,
      max: 100,
      show: false,
      // boundaryGap: ['40%', '40%'],
      startAngle: 90,
    },
    radiusAxis: {
      type: 'category',
      show: true,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    polar: [
      {
        center: ['50%', '50%'], //中心点位置
        radius: '100%', //图形大小
      },
    ],
    series: [
      {
        type: 'bar',
        z: 10,
        name: '完成度',
        data: [value],
        radius: ['100%', '100%'],
        center: ['50%', '50%'], //中心点位置
        showBackground: false,
        backgroundStyle: {
          borderWidth: 2,
          width: 2,
        },
        coordinateSystem: 'polar',
        roundCap: true,
        barWidth: 8, //大的占比环
        itemStyle: {
          normal: {
            opacity: 1,
            color: theme['--bg-color3'],
          },
        },
      },
      {
        type: 'pie',
        name: '内层细圆环',
        radius: ['55%', '45%'],
        center: ['50%', '50%'], //中心点位置
        startAngle: 90,
        hoverAnimation: false,
        clockWise: true,
        silent: true,
        itemStyle: {
          normal: {
            color: '#f3f3f7',
            shadowBlur: 0,
            shadowColor: '#66666a',
          },
        },
        tooltip: {
          show: false,
        },
        label: {
          show: false,
        },
        data: [100],
      },
    ],
  };
}

export function useBudgetInfo(
  budgeChart: any,
  budgeOptions: echarts.EChartsOption,
  chartId: string
): any {
  const data: any = reactive({
    info: {
      budget: { money: '' },
      totalMoney: 0,
      percent: 0,
      suplus: 0,
    },
  });

  onMounted(() => {
    methods.initData();
  });

  onUnmounted(() => budgeChart && budgeChart.dispose());

  const methods = {
    // 初始化数据
    initData() {
      methods.getPercentBudgetInfo();
    },
    // 获取预算信息
    async getPercentBudgetInfo() {
      BudgetReq.budgetPercentInfo().then((res) => {
        let resData: any = res.data;
        if (notNull(resData.budget) && notNull(resData.budget.money)) {
          data.info = { ...resData };
          data.info.percent = Number(data.info.percent * 100).toFixed(0);

          nextTick(() => {
            methods.initBudgeChart();
            methods.renderBudgeChart();
          });
        }
      });
    },
    // 初始化图表
    initBudgeChart() {
      if (notNull(budgeChart)) return;
      let chartWrap = document.getElementById(chartId);

      if (chartWrap) {
        budgeChart = echarts.init(chartWrap);
      }
    },
    // 渲染图表
    renderBudgeChart() {
      budgeOptions = genBudgeOptions(data.info.percent);
      budgeChart.setOption(budgeOptions);
    },
  };

  return [toRefs(data).info, methods.getPercentBudgetInfo];
}
