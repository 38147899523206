import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createBlock(_component_van_popup, {
    show: _ctx.showLedgerType,
    "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showLedgerType) = $event)),
    position: "bottom",
    round: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_picker, {
        columns: _ctx.ledgerColumns,
        onCancel: _ctx.onCancel,
        onConfirm: _ctx.onSelectLedger
      }, null, 8, ["columns", "onCancel", "onConfirm"])
    ]),
    _: 1
  }, 8, ["show"]))
}