import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_date_picker = _resolveComponent("van-date-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createBlock(_component_van_popup, {
    show: _ctx.show,
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.show) = $event)),
    position: "bottom",
    round: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_date_picker, {
        modelValue: _ctx.currentDate,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentDate) = $event)),
        title: "选择年月",
        formatter: _ctx.formatter,
        "columns-type": _ctx.columnsType,
        "min-date": _ctx.minDate,
        "max-date": _ctx.maxDate,
        "visible-option-num": 5,
        onConfirm: _ctx.confirm,
        onCancel: _ctx.cancel
      }, null, 8, ["modelValue", "formatter", "columns-type", "min-date", "max-date", "onConfirm", "onCancel"])
    ]),
    _: 1
  }, 8, ["show"]))
}