<template>
  <div class="user">
    <div class="head">
      <img class="avatar" src="/common/images/avatar.png" />
      <div class="info">
        <span class="name">{{ user.username || '' }}</span>
        <span class="slogen">个性签名：{{ user.signature || '' }}</span>
      </div>
    </div>
    <div class="budge-card" v-if="showBudgetCard">
      <header class="flex-row-all-center">
        <span>{{ month }}月总预算</span>
        <div>
          <span>查看全部</span>
        </div>
      </header>
      <div class="budge-card__content">
        <div class=".budge-chart-wrap">
          <div id="BudgetChart2"></div>
        </div>
        <div>
          <div class="budge-item">
            <label>剩余预算：</label>
            <span>{{ info.suplus }}</span>
          </div>
          <div class="budge-item">
            <label>本月预算：</label>
            <span>{{ info.budget.money }}</span>
          </div>
          <div class="budge-item">
            <label>本月支出：</label>
            <span>{{ Number(info.totalMoney).toFixed(2) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-wrap">
        <van-cell
          icon="orders-o"
          @click="toConfigBillType"
          title="配置类别"
          is-link
        />
        <van-cell
          icon="edit"
          @click="visibleSig = true"
          title="修改个性签名"
          is-link
        />
        <van-cell icon="setting-o" to="/account" title="账户安全" is-link />
        <van-cell icon="bag-o" to="/takeout_praise" title="外卖好评" is-link />
        <van-cell icon="bag-o" to="/target" title="目标管理" is-link />
        <van-cell
          icon="good-job-o"
          @click="visibleRate = true"
          title="软件评分"
          is-link
        />

        <van-collapse class="bottom-collapse" v-model="activeNames">
          <van-collapse-item icon="shop-o" title="换肤" name="2">
            <van-cell title="亮色" @click="changeColor('light')">
              <van-tag color="#ffda44">#ffda44</van-tag>
            </van-cell>
            <van-cell title="浅绿" @click="changeColor('green')">
              <van-tag color="#39be77">#39be77</van-tag>
            </van-cell>
            <van-cell title="粉色" @click="changeColor('pink')">
              <van-tag color="#faafaa">#faafaa </van-tag>
            </van-cell>
            <van-cell title="蓝色" @click="changeColor('blue')">
              <van-tag color="#87ceeb">#87ceeb </van-tag>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>

    <van-dialog
      v-model:show="visibleSig"
      @confirm="confirmSig"
      title="个性签名"
      show-cancel-button
    >
      <van-field
        v-model="signature"
        rows="2"
        type="textarea"
        maxlength="20"
        placeholder="请输入个性签名"
        show-word-limit
        autosize
      />
    </van-dialog>
    <van-dialog
      class="rate"
      v-model:show="visibleRate"
      title="软件评分"
      show-cancel-button
    >
      <van-rate
        class="rate"
        v-model="rate"
        void-icon="star"
        void-color="#eee"
        allow-half
      />
    </van-dialog>
  </div>
</template>

<script lang="ts">
import { onMounted, reactive, toRefs, onUnmounted, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import changeTheme from '@/theme/theme';
import { showToast } from 'vant';
import * as echarts from 'echarts';
import { useBudgetInfo } from '@/utils/hooks/budget';
import { notNull } from '@/utils/judgeTool';
import { UsersReq } from '@/request/users';
import dayjs from 'dayjs';

let budgeOptions: echarts.EChartsOption;

let budgeChart: any;

export default {
  name: 'user',
  setup() {
    const router = useRouter();
    let month = ref(dayjs().month() + 1);
    let [info, renderBudgeChart] = useBudgetInfo(
      budgeChart,
      budgeOptions,
      'BudgetChart2'
    );
    const state = reactive({
      user: {}, // 用户信息
      signature: '',
      visibleSig: false,
      visibleRate: false,
      rate: 4.5,
      activeNames: [1],
    });

    const computeds = {
      showBudgetCard: computed(
        () => notNull(info.value.budget) && notNull(info.value.budget.money)
      ),
    };

    onMounted(() => {
      methods.getUserInfo();
    });

    const methods = {
      // 获取用户信息
      async getUserInfo() {
        const { data }: any = await UsersReq.getUserInfo();
        state.user = data;
        state.signature = data.signature;
      },
      // 修改签名
      async confirmSig() {
        const { data } = await UsersReq.updateInfo({
          signature: state.signature,
        });
        state.user = data;
        state.signature = data.signature;
      },
      // 换肤
      async changeColor(color: string) {
        changeTheme(color);
        renderBudgeChart();
        showToast('切换成功');
      },
      // 跳转配置记账类型
      toConfigBillType() {
        router.push('/config-bill-type');
      },
    };

    return {
      month,
      info,
      ...toRefs(state),
      ...computeds,
      ...methods,
    };
  },
};
</script>

<style lang="scss" scoped>
.user {
  min-height: 100%;
  background-color: #f5f5f5;
  padding: 12px;
  .head {
    display: flex;
    background: var(--bg-color3);
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 12px;
    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #fff;
      margin-right: 10px;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .name {
        font-size: 18px;
        color: var(--text-color7);
        font-weight: 500;
      }
      .slogen {
        color: var(--text-color7);
        background: rgba(0, 0, 0, 0.1);
        padding: 3px 5px;
        border-radius: 10px;
      }
    }
  }
}
:deep(.van-cell--clickable:active) {
  background: var(--bg-color8);
}
.budge-card {
  background: var(--bg-color4);
  margin-bottom: 12px;
  padding: 12px;
  padding-bottom: 0;
  box-sizing: border-box;
  border-radius: 5px;

  &:active {
    background: var(--bg-color8);
  }

  header {
    justify-content: space-between;

    span {
      font-size: 14px;
    }
    & > div {
      span {
        font-size: 12px;
        color: rgb(187, 184, 184);
      }
    }
  }

  .budge-card__content {
    @include flex-row-all-center();
    justify-content: space-between;
    width: 100%;
    & > div:nth-child(2) {
      flex: 1;
      margin-left: 12px;
    }
    .budge-item {
      @include flex-row-all-center();
      justify-content: space-between;
      margin-bottom: 12px;
      padding-right: 24px;

      &:nth-child(1) {
        margin-top: 3px;
        label {
          font-size: 14px;
        }
        span {
          font-size: 16px;
          font-weight: bold;
        }
      }
      &:not(:nth-child(1)) {
        font-size: 12px;
        color: rgb(194, 194, 194);
      }
    }
  }

  #BudgetChart2 {
    width: 150px;
    height: 150px;
  }
}
.content-wrap {
  border-radius: 5px;
  overflow: hidden;
}
.content {
  padding-bottom: 80px;
}

:deep(.rate) {
  .van-dialog__content {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
