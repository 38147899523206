import { createRouter, createWebHashHistory, RouteLocation, RouteRecordRaw } from 'vue-router';
import storage from '@/utils/storage';
import { store, useCacheStore } from '@/store';
import Layout from '@/layout/index';
import Home from '@/views/Home';
import Statistics from '@/views/Statistics';
import Invest from '@/views/Invest/Invest';
import User from '@/views/User/User';
import { isNull, notNull } from '@/utils/judgeTool';
import { IRouteLocation, IRouteRecordRaw } from '@/types/router';
import { BaseObject } from '@/utils/baseTypes';

// 缓存依赖收集
const rouerCacheMap: Record<string, Set<string>> = {};
const routes: Array<IRouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    redirect: 'home',
    component: Layout,
    meta: { keepAlive: true },
    children: [
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta: { keepAlive: true },
      },
      {
        path: 'statistics',
        name: 'statistics',
        component: Statistics,
        meta: { keepAlive: true },
      },
      {
        path: 'invest',
        name: 'invest',
        component: Invest,
        meta: { keepAlive: true },
      },
      {
        path: 'user',
        name: 'user',
        component: User,
        meta: { keepAlive: true },
      },
    ],
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/Detail'),
    meta: {
      title: '账单详情',
      index: 2,
      keepAlive: false,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/User/Account'),
    meta: {
      title: '账号安全',
      index: 2,
      keepAlive: false,
    },
  },
  {
    path: '/takeout_praise',
    name: 'takeout_praise',
    component: () => import('@/views/Tool/TakeoutPraise.vue'),
    meta: {
      title: '外卖好评',
      index: 2,
      keepAlive: false,
    },
  },
  {
    path: '/target',
    name: 'target',
    component: () => import('@/views/Target/index.vue'),
    meta: {
      title: '目标管理',
      index: 2,
      keepAlive: false,
    },
  },
  {
    path: '/add_target',
    name: 'add_target',
    component: () => import('@/views/Target/AddOrEditTarget/index.vue'),
    meta: {
      title: '添加目标',
      index: 3,
      keepAlive: false,
      // accessNeedCachePath: ['target'],
    },
  },
  {
    path: '/commonly_used_target',
    name: 'commonly_used_target',
    component: () => import('@/views/Target/CommonlyUsedTarget/index.vue'),
    meta: {
      title: '常用任务',
      index: 3,
      // accessNeedCachePath: ['target'],
    },
  },
  {
    path: '/current_time_line',
    name: 'current_time_line',
    component: () => import('@/views/Target/CurrentTimeLine/index.vue'),
    meta: {
      title: '今日数据',
      index: 3,
      // accessNeedCachePath: ['target'],
    },
  },
  {
    path: '/config-bill-type',
    name: 'config-bill-type',
    component: () => import('@/views/BillType/ConfigBillType'),
    meta: {
      title: '配置记账类型',
      index: 2,
    },
  },
  {
    path: '/add-bill-type',
    name: 'add-bill-type',
    component: () => import('@/views/BillType/AddBillType'),
    meta: {
      title: '添加记账类型',
      index: 3,
      accessNeedCachePath: ['config-bill-type'],
    },
  },
  {
    path: '/budget',
    name: 'budget',
    component: () => import('@/views/Budget/Budget'),
    meta: {
      title: '预算',
      index: 2,
    },
  },
  {
    path: '/toloan_list',
    name: 'toloan_list',
    component: () => import('@/views/ToLoan/ToLoanList.vue'),
    meta: {
      title: '借贷',
      index: 2,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/User/Login'),
  },
];

function convertRouteTreeToList(tree: RouteRecordRaw[]) {
  let list: any[] = [];
  tree.forEach((item) => {
    list.push(item);

    if (item.children && item.children.length) {
      list = list.concat(convertRouteTreeToList(item.children));
    }
  });
  return list;
}
export const routeList = convertRouteTreeToList(routes);

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const whiteList = ['/login', '/404', '/401'];
router.beforeEach(async (to: IRouteLocation, from: IRouteLocation, next: any) => {
  document.documentElement.scrollTo({ top: 0 });

  const hasToken = storage.getItem('token');
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' });
    } else {
      const info = store.state.userInfo;

      if (info) {
        next();
      } else {
        try {
          await store.dispatch('getUserInfo');
          next({ ...to, replace: true });
        } catch (error) {
          //(token过期等情况)
          await store.dispatch('logout');
          next(`/login`);
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login`);
    }
  }
});

router.beforeResolve((to: IRouteLocation, from: IRouteLocation, next: any) => {
  let toIndex: any = to.meta.index || 0,
    fromIndex: any = from.meta.index || 0;
  if ((toIndex === 0 && fromIndex === 0) || from.path === '/') {
    store.commit('setAnimation', '');
  } else {
    if (fromIndex < toIndex) {
      store.commit('setAnimation', 'slide-right');
    } else {
      store.commit('setAnimation', 'slide-left');
    }
  }

  // 在路由离开的时候，对不同进入路径下的组件，进行动态keepAlive
  // 这里keepAlive如果是嵌套组件，则要在外层router-view，
  // 内层router-view都要做控制
  // 动态控制keepAlive
  // 例如 home -> detail，缓存。home -> other 不缓存。
  const { addCacheRoute, delCacheRoute, hasCacheRoute } = useCacheStore();
  let fromName = String(from.name),
    toName = String(to.name);
  let fromCacheList = rouerCacheMap[fromName];
  if (notNull(to.meta) && notNull(to.meta.accessNeedCachePath) && notNull(from.name)) {
    let accessNeedCachePath = to.meta.accessNeedCachePath;
    if (accessNeedCachePath?.includes(fromName)) {
      addCacheRoute(fromName);
      if (notNull(fromCacheList)) {
        fromCacheList?.add(toName);
      } else {
        rouerCacheMap[fromName] = new Set();
        rouerCacheMap[fromName].add(toName);
      }
      return next();
    }
  }
  if (notNull(rouerCacheMap[fromName]) && !fromCacheList.has(toName)) {
    delCacheRoute(fromName);
    return next();
  }
  // 实现动态缓存，普通情况下把，初始设置为缓存的组件缓存住
  // 但是如果操作store更新了缓存列表，则可以刷新缓存
  if (from.meta.keepAlive && !Boolean(hasCacheRoute(fromName))) {
    addCacheRoute(fromName);
  }

  next();
});

export default router;
