import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pop" }
const _hoisted_2 = { class: "header van-hairline--bottom" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "expense-wrap" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "income-wrap" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createBlock(_component_van_popup, {
    show: _ctx.show,
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.show) = $event)),
    position: "bottom",
    style: { height: '80vh' },
    round: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createTextVNode(" 请选择类型 ")),
          _createVNode(_component_van_icon, {
            name: "cross",
            class: "cross",
            onClick: $setup.toggle
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.choseType({ id: 'all' }))),
            class: _normalizeClass({ all: true, active: _ctx.active == 'all' })
          }, " 全部类型 ", 2),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "title" }, "支出", -1)),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expense, (item) => {
              return (_openBlock(), _createElementBlock("p", {
                onClick: ($event: any) => ($setup.choseType(item)),
                class: _normalizeClass({ active: _ctx.active == item.id }),
                key: item
              }, _toDisplayString(item.name), 11, _hoisted_5))
            }), 128))
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "title" }, "收入", -1)),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.income, (item) => {
              return (_openBlock(), _createElementBlock("p", {
                onClick: ($event: any) => ($setup.choseType(item)),
                class: _normalizeClass({ active: _ctx.active == item.id }),
                key: item
              }, _toDisplayString(item.name), 11, _hoisted_7))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}