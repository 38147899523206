import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header-date" }
const _hoisted_2 = { style: {"margin-left":"12px"} }
const _hoisted_3 = { class: "money" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex-row-center" }
const _hoisted_7 = {
  class: "custom-title",
  style: {"margin-left":"5px"}
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  class: "sell-money"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_van_tag = _resolveComponent("van-tag")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!

  return (_openBlock(), _createBlock(_component_van_cell_group, { class: "item" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createTextVNode(_toDisplayString($props.bill.bill_date) + " ", 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.billDateWeek), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.expense)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("b", null, "支", -1)),
                _createTextVNode(_toDisplayString(_ctx.expense.toFixed(2)), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.income)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _cache[1] || (_cache[1] = _createElementVNode("b", null, "收", -1)),
                _createTextVNode(_toDisplayString(_ctx.income.toFixed(2)), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.bill.bills, (v, i) => {
        return (_openBlock(), _createBlock(_component_van_cell, {
          key: i,
          label: `${v.remark ? v.remark : ''}`,
          onClick: ($event: any) => (_ctx.goToDetail(v))
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              (!_ctx.isTotoanLedger(v))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_icon, {
                      "icon-class": v.bill_type_icon,
                      "wrap-class": "icon-container"
                    }, null, 8, ["icon-class"]),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(v.bill_type_name), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.isTotoanLedger(v))
                ? (_openBlock(), _createElementBlock("h2", _hoisted_8, _toDisplayString(_ctx.renderToloanText(v)) + "：" + _toDisplayString(v.borrower), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          value: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("p", null, _toDisplayString(`${_ctx.renderSourceTypeText(v)}${Number(v.money).toFixed(2)}`), 1),
              (_ctx.showSellMoney(v))
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, " 卖出" + _toDisplayString(Number(v.sell_money).toFixed(2)), 1))
                : _createCommentVNode("", true)
            ]),
            (v.holding_status)
              ? (_openBlock(), _createBlock(_component_van_tag, {
                  key: 0,
                  color: _ctx.holdingStatusTagColor[v.holding_status],
                  plain: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.renderStatusTag(v)), 1)
                  ]),
                  _: 2
                }, 1032, ["color"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["label", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}