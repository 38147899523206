import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.$route.meta.keepAlive)
    ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, {
            name: $setup.animation,
            model: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_KeepAlive, { include: $setup.cacheRouteList }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  class: "page",
                  key: _ctx.getFirstLevelRoute(route)
                }))
              ], 1032, ["include"]))
            ]),
            _: 2
          }, 1032, ["name"])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, {
            name: $setup.animation,
            model: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                class: "page",
                key: _ctx.getFirstLevelRoute(route)
              }))
            ]),
            _: 2
          }, 1032, ["name"])
        ]),
        _: 1
      }))
}